* {
	font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif !important;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.headingContainer {
	width: 100%;
	text-align: center;
}

.headingContainer > h1 {
	margin-bottom: 0px;
}

.RaList-main > .MuiToolbar-regular {
	height: 40px !important;
	min-height: 40px !important;
}

.RaLayout-appFrame {
	margin-top: 65px !important;
}

.MuiButton-root {
	color: #e7806d !important;
}

.Mui-disabled {
	color: lightgrey !important;
}

.MuiButton-containedPrimary {
	color: white !important;
	background: linear-gradient(to right, #e7806d, #eb9a59);
}

.MuiButton-containedPrimary:hover {
	opacity: 0.9;
}

.RaDatagrid-headerCell {
	font-weight: bold !important;
}

.MuiInputBase-input,
.MuiFormControl-root {
	width: 100% !important;
	max-width: 400px !important;
}

.loginInput,
.loginInput > div,
.loginInput > div > input {
	max-width: 100% !important;
}

.MuiInputBase-root:after {
	border-color: #e6816b !important;
}

.Mui-checked {
	color: #e6816b !important;
}

.Mui-focused > span {
	color: #e6816b !important;
}

.MuiSwitch-track {
	background-color: #e6816b !important;
}

.whiteBorder > div > fieldset {
	border-color: white !important;
}

.whiteBorder > label {
	color: white !important;
}

h4 {
	margin: 0;
	margin-bottom: 2px;
	margin-top: 10px;
}

.MuiFormHelperText-root {
	height: 9px;
}
